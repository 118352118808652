@import '../../scss/utils/mixins.scss';

.report-checkbox-container {
    .checkbox-field {
        width: 50% !important;
      
        @include media(lg) {
            width: 33% !important;
        }
    }
}