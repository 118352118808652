form {
  .form-group {
    &--invalid {
      textarea {
        border-color: var(--danger) !important;

        &:focus {
          border-color: var(--danger) !important;
        }
      }

      input {
        border-color: var(--danger) !important;

        &:focus {
          border-color: var(--danger) !important;
        }
      }

      label.validate {
        color: var(--danger) !important;
      }

      .custom-react-select {
        div[class$="control"] {
          border-color: var(--danger) !important;
        }
      }

      .react-time-picker {
        border-color: var(--danger) !important;
      }
      .content-editor__wrapper {
        border-color: var(--danger) !important;
      }
      .dropdown-filter {
        button {
          border-color: var(--danger) !important;

          &:focus {
            border-color: var(--danger) !important;
          }
        }
      }
    }

    &__custom-checkbox {
      label {
        font-size: rem(14);
        font-weight: bold;
        color: var(--grey-light-4);
        border-radius: rem(2);
        margin: 0 rem(5);
        width: rem(32);
        height: rem(44);
        background-color: var(--dark-lighter--2);

        &:hover {
          cursor: pointer;
        }
      }

      input[type="checkbox"] {
        position: absolute;
        visibility: hidden;
        opacity: 0;
        z-index: -10;

        &:checked + label {
          background-color: var(--aqua-blue);
        }
      }
    }
    .custom-control.custom-checkbox .custom-control-input:checked ~ .custom-control-label::before{
      color: #fff;
      border-color: #19657E;
      background-color: #19657E;
    }

    .custom-control.custom-checkbox .custom-control-input--white:checked ~ .custom-control-label::before{
      color: #19657E;
      border-color: #BCB8B2;
      background-color: #ffffff;
    }
    .custom-checkbox .custom-control-input--white:checked ~ .custom-control-label::after {
      background-image: url('../../assets/icons/check.svg');
      background-size: 70% 70%;
    }
    .custom-control.custom-checkbox .custom-control-label::before,
    .custom-control.custom-checkbox .custom-control-label::after{
      width: rem(20) !important;
      height: rem(20) !important;
    }

    label {
      color: var(--dark-lighter);
      font-weight: bold;
      font-size: rem(14);
      font-size: rem(14);
      margin-bottom: rem(5);
      position: relative;

      &.required {
        &:after {
          content: "*";
          position: absolute;
          top: rem(-2);
          right: rem(-8);
          color: var(--danger);
        }
      }
    }
    .custom-checkbo .custom-control-label {
      &::before {
        border: 1px solid #BCB8B2;
        border-radius: 6px;
        top: 3px;
      }

      &::after {
        top: 3px;
      }
    }

    .custom-checkbox .custom-control-input:checked {
      & ~ .custom-control-label::before {
        background-color: var(--aqua-blue);
        border: 1px solid #BCB8B2;
        border-radius: 6px;
      }
    }
    .custom-checkbox {
      .custom-control-label {
        &::before {
          top: 2px;
        }
        &::after {
          top: 2px;
        }
      }
    }
    input#postalCode {
      text-transform: uppercase;
      &::placeholder {
        text-transform: none;
      }
      &::-webkit-input-placeholder {
        text-transform: none !important;
      }
      &:-moz-placeholder {
          text-transform: none !important;
      }
      &::-moz-placeholder {
          text-transform: none !important;
      }
      &::-ms-input-placeholder {
          text-transform: none !important;
      }
    }
  }

  .form-control {
    &--searchByName {
      .react-autosuggest__container {
        width: 90%;
      }
    }
  }

  &.filters-form-group {
    padding: rem(6) rem(20) rem(10) rem(10);

    .custom-react-select {
      width: 100%;
      @include media(lg) {
        max-width: rem(185);
      }
    }
    .custom-react-select-sm {
      width: 100%;
      @include media(lg) {
        max-width: rem(165);
      }
    }
    .custom-react-select-lg {
      width: 100%;
      @include media(lg) {
        max-width: rem(220);
      }
      &--24 {
        @include media(lg) {
          max-width: 24%;
        }
      }
    }
    .custom-react-select-xl {
      width: 100%;
      @include media(lg) {
        max-width: rem(250);
      }
    }

    .custom-react-multi-select.custom-react-select {
      width: 100%;
      @include media(lg) {
        max-width: rem(280);
      }
    }

    &--dashboard {
      .custom-react-select {
        width: 100%;
        &__placeholder {
          font-size: rem(14.5) !important;
        }
        @include media(md) {
          max-width: rem(185);
          &--jobSchedule {
            max-width: rem(235);
          }
          &--zone {
            max-width: rem(155);
          }
          &--schoolYear {
            max-width: rem(225);
          }
          &--showNext {
            max-width: rem(200);
          }
          &--small {
            max-width: rem(125);
          }
          &--checkType {
            max-width: rem(205);
          }
        }
      }
    }

    border: 1px solid #DADADA;
    border-radius: 4px;
  }
  .holidays-filter {
    .custom-react-select {
      width: 100%;
      @include media(lg) {
        max-width: rem(225);
      }
    }
  }

  .custom-control {
    z-index: 0;

    &.custom-control-radio {
      .custom-control-radio-label {
        padding: 0 10px;

        &:before {
          top: -1.5px;
        }

        &:before, &:after {
          width: rem(24);
          height: rem(24);
          border-radius: 50%;
          border: 1px solid var(--dark-lighter--2);
          background-color: #fff;
        }

        &:after {
          width: rem(12);
          height: rem(12);
          background-color: transparent;
          border: 0;
          top: rem(5);
          left: rem(-18);
        }
      }

      input[type="radio"]:checked + label:after {
        background-color: var(--aqua-blue);
      }
    }
  }
  // input {
  //   &#employeeId {
  //     text-transform: uppercase;
  //     &::placeholder {
  //       text-transform: none;
  //     }
  //   }
  // }
}


input[type="text"].form-control, input[type="number"].form-control, input[type="email"].form-control, input[type="file"].form-control,
input[type="password"].form-control,
.custom-react-select div[class$="control"], textarea.form-control {
  font-size: rem(14.5);
  min-height: rem(44);
  border: 1px solid #BCB8B2;
  border-radius: 6px;
  color: #000;
  font-weight: bold;

  &:focus {
    outline: none;
    box-shadow: none;
    border-color: #BCB8B2;
    color: #000;
  }

  &::placeholder {
    color: #706E6A;
    font-weight: normal;
  }
}

.google-places-container {
}
.google-places {

  &__value-container {
    // padding: 0 rem(15);
    border: none;
    min-height: rem(42);

  }

  &__control {
    border: 1px solid #BCB8B2 !important;
    border-radius: 6px !important;
    font-size: rem(14.5);
    border: none;
    outline: none;
    color: #000;
    font-weight: bold;
    &:focus {
      outline: none;
      box-shadow: none;
      border-color: #BCB8B2;
      color: #000;
    }

    &--is-focused {
      outline: none;
      box-shadow: none !important;
      border-color: #BCB8B2 !important;
      color: #000;
    }
  
  }

  &__placeholder {
    color: #706E6A;
    font-weight: normal;
    font-family: $font-family-base;
  }

 &__indicators {
  display: none !important;
 }

}
textarea {
  height: auto !important;
  resize: none;
}

button.btn, a.btn {
  font-size: rem(15);
  height: rem(44);
  padding-left: rem(9);
  padding-right: rem(9);
  font-weight: bold;
  color: var(--dark);
  border-radius: rem(4);
  display: inline-flex;
  align-items: center;
}

table {
  tbody {
    td {
      button.btn {
      // button.btn:not(&.btn--lg) {
        width: rem(38) !important;
        height: rem(38) !important;

        &.btn-disabled {
          @include disabledBtn;
        }
      }
      button.btn.btn--lg {
        width: rem(137) !important;
        height: rem(40) !important;
      }
    }
  }
}

button.btn {
  &.btn-disabled {
    @include disabledBtn;
  }

  &.btn-badge {
    height: rem(30);
    background-color: var(--grey-light-1);
  }
}

body .custom-react-select {
  &__menu {
    width: auto;
    min-width: 100%;
    z-index: 30;
  }
  &__menu-list {
    padding-top: 0;
    padding-bottom: 0;
  }
  &__control {
    &--is-focused {
      box-shadow: none !important;
    }
  }
  &__value-container {
    width: auto !important;
    padding-right:0;
  }
  &__indicators {
    .custom-react-select__indicator {
      padding-left: 0;
    }
  }

  &__option {
    font-family: $font-family-base;
    font-weight: 400;
    font-size: rem(15);
    line-height: 17px;
    color: var(--dark);
    white-space: nowrap;
    &:hover {
      cursor: pointer;
    }

    &--is-disabled {
      color: #DEDEDE;
      opacity: 0.5;
      cursor: not-allowed;
      display: none;
    }

    &--is-focused {
      font-weight: 700;
      background-color: #EEEEE5;
    }
    &--is-selected {
      font-weight: 700;
      background-color: #EEEEE5;
    }
  }

  &__clear-indicator {
    padding: 0 !important;
  }

  &:focus {
    outline: none !important;
  }

  span[class$="indicatorSeparator"] {
    display: none;
  }

  div[class$="control"] {
    background-color: #FAFAFA;
  }

  div[class$="multiValue"] {
    background-color: var(--aqua-blue);
    color: var(--dark);

    div {
      font-size: 72%;
      padding: 2px;
    }
  }

  div[class$="placeholder"] {
    font-size: rem(15);
    font-weight: bold !important;
    color: #000;
    opacity: 1;
    //
    max-width: calc(100% - 8px);
    overflow: hidden;
    position: absolute;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  &--fixed-size {
    width: rem(300);
  }

  &--tenant {
    min-width: rem(225);

    @media (max-width: 996px) {
      .custom-react-select {
        min-width: auto;
        
      }
    }
  }

  .custom-react-select {
    &__menu {
      &_list {
        background-color: var(--aqua-haze);
      }
    }
  }
}

//@media (max-width: 340px) {
//  .custom-react-select {
//    max-width: rem(132) !important;
//  }
//}


.btn-outline-blue:hover, .btn-outline-blue:active, .btn-outline-blue:visited {
  color: #ffffff !important;
  svg {
    fill: #fff !important;
    path {
      stroke: #fff !important;
      
    }
  }
}

.btn-outline-blue {
  svg {
    fill: var(--blue);
  }
}