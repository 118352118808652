.job__from-nav__card {
    border: 1px solid var(--alto);
    border-radius: 4px;
    background-color: #fff;
    padding: rem(10) rem(10);
    ul {
       position: relative;
       &:after {
            @include media(sm) {
               content: "";
            }
            position: absolute;
            left:0;
            right:0;
            margin: auto;
            width: 100%;
            height: 4px;
            top: 40%;
            transform: translateY(-50%);
            width: 75%;
            background: var(--alto);
            z-index: 1;
       }
    }
    &__step {
        margin-bottom: 0;
        flex-grow: 1;
        flex-basis: 0;
        min-width: rem(200);
        z-index: 2;
        h6 {
            font-size: rem(14);
            font-weight: bold;
            color: var(--dark);
            margin-bottom: rem(10);
        }
        span {
            font-size: rem(28);
            font-weight: bold;
            color: #fff;
            width: rem(48);
            height: rem(48);
            background-color: var(--dark);
            border-radius: 50%;
            position: relative;
            // z-index: 4;
            // &:after {
            //     content: "";
            //     position: absolute;
            //     height: 4px;
            //     top:50%;
            //     left:0;
            //     transform: translateY(-50%);
            //     width: 100%;
            //     background: var(--alto);
            //     z-index: 1;
            // }
        }
        small {
            font-size: rem(14);
            font-weight: 300;
            color: var(--dark-lighter);

        }
        &--active {
            span {
                background-color: var(--aqua-blue);
                color: var(dark);
            }
        }
    }
}


.bottom-nav-card {
    border: 1px solid var(--alto);
    border-radius: 4px;
    background-color: #fff;
    padding: rem(15) rem(10);
}