.react-autosuggest {
    &__container {
        position: relative;
    }
    &__input {
        width: 100%;
        font-family: Arial;
        color: #000;
        font-size: 0.90625rem;
        font-style: normal;
        font-weight: bold;
        height: 2.75rem;
        border: 1px solid #bcb8b2;
        border-radius: 6px;
        padding: 0.375rem 0.625rem;
        &--focused {
            outline: none;
        }
        &::placeholder {
            color: #706e6a;
        }
    }
    &__suggestions-container {
        display: none;
        &--open {
            display: block;
            position: absolute;
            top: 45px;
            left: 9px;
            right: 9px;
            width: 222px;
            max-height: 300px;
            overflow: auto;
            background: #f7f9fa;
            z-index: 2;
            box-shadow: 0px 3px 8px rgba(59, 59, 59, 0.404);
            &::-webkit-scrollbar {
                width: 8px;
            }
            &::-webkit-scrollbar-track {
                background: transparent;
            }
            &::-webkit-scrollbar-thumb {
                background: rgba(100, 100, 100, 0.63);
                border-radius: 7px;
            }
            &::-webkit-scrollbar-thumb:hover {
                background: rgb(100, 100, 100);
            }
        }
    }
    &__suggestions-list {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
    &__suggestion {
        cursor: pointer;
        padding: 10px 20px;
        color: #283145;
        &--highlighted {
            background-color: #eeeee5;
            font-weight: bold;
        }
    }
}
.shfit-autosegest {
    .react-autosuggest__container {
        width: 100%;
    }
}
