.details {
    &-information {
        &__title {
            h6 {
                font-size: rem(14);
                font-weight: 700;
                color: var(--dark);
            }
        }
        &__title-xl {
            h6 {
                font-size: rem(16);
                font-weight: 700;
                color: var(--dark);
            }
        }
        &__value {
            p {
                font-size: rem(14);
                font-weight: 400;
                color: var(--dark);
            }
        }
    }
}
.tabs-details {
    overflow-y: hidden;
    flex-wrap: inherit!important;
}
.w-100-md-0 {
    width: 100%;
    @include media(sm) {
        width: auto;
    }
}

.deactivate-btn-container {
  position: absolute;
  top: 29px;
  right: 0;
  z-index: 1;
  min-width: 190px;
  display: flex;
  justify-content: end;
  flex-direction: column;
  &--lg {
    min-width: rem(265);
  }
}


.buttons-container {
  position: relative;

  @media (max-width: 576px) {
    margin-top: 16px;
    margin-bottom: 60px;
    margin-left: auto;
  }
}